import TrackMapDeliveryBox from './TrackMapDeliveryBox.js';
import invertColor from "/public/react/src/helpers/invertColor.js";

(function (window) {
    const version = "0.5.3";
    let TFT;
    let isInitClientReportingPanel = false;
    if (typeof window.TFT === "function" && window.TFT() === version) {
        TFT = window.TFT;
    }

    TFT = function () {
        return TFT.version;
    };

    TFT.options = {
        map: {
            apikey: null,
            provider: 'here'
        },
        data: {
            currentDelivery: {
                isInTransit: true,
                latitude: 0,
                longitude: 0
            },
            pastDeliveries: [],
        },
        orderKey: null,
        shipperId: null,
        clientCode: null,
        host: null,
        endpoint: '/api/track',
        target: null,
        travelMode: 'BICYCLING',
        isPreview: false
    };

    TFT.updateDropOffPoint = false;
    TFT.updateSafePlace = false;

    TFT.buildType = typeof __ASSET_ENV__ !== "undefined" && __ASSET_ENV__ === 'production' ? 'build' : 'src';
    TFT.assetVersion = (typeof __ASSET_VERSION__ !== "undefined" ? __ASSET_VERSION__ : '');

    TFT.EVENT_DEPRES = 196; //livré en lieu sûr
    TFT.EVENT_DEPRESFDS = 211; //livré en lieu sûr - FSD
    TFT.EVENT_DEPRESENOT = 213; //livré en lieu sûr - ENOTIF
    TFT.EVENT_LIVPR = 199;  //livré en point relais
    TFT.EVENT_LIVPRFDS = 215;  //livré en point relais - FDS
    TFT.EVENT_LIVPRENOT = 216;  //livré en point relais - ENOTIF
    TFT.EVENT_OKPR = 39;  //livré en point relais
    TFT.EVENT_RNDEND_LIVPR = 238;  //tournée terminé avec livraison en point relais
    TFT.EVENT_PAQ = 202;    //Récupération du colis directement au dépôt GLS
    TFT.EVENT_RPGM0 = 32    //livré en main propre

    TFT.log = function log(type, callFunction, ...args) {
        let _a;
        const header = "TFT::" + callFunction.name + " " + (type === "error" ? "Error:" : ":");
        (_a = console[type]) === null || _a === void 0 ? void 0 : _a.call(header, ...args);
    };

    TFT.isMobile = function isMobile() {
        return TFT.options.device === 'mobile' || ((window && window.innerWidth) || screen.width) <= 768;
    };

    // Helpers
    TFT.isTrue = value => value === 'true' || value === true;

    // get SVG Form and Style
    // support on top of from 0 to 999
    TFT.svgMarkerCreator = function (number, style) {

        const {numberFont, numberBackground, parcelMarkerType} = style;
        const invertedNumberColor = parcelMarkerType === 'empty' ? 'black' : invertColor(numberBackground);

        let x, y = 16, fontSize;

        if (number < 10) {
            x = 8;
            fontSize = 20;
        } else if (number < 100) {
            x = 4;
            fontSize = 14;
        } else {
            x = 4;
            fontSize = 11;
            y = 15;
        }

        return (`
            <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
                <text font-family="${numberFont}" x="${x}" y="${y}" fill="${invertedNumberColor}" font-size="${fontSize}"
                      font-weight="bold">${number}
                </text>
            </svg>`).trim();
    };

    /**
     * Split to groups not exceeds 25 point in each group
     */
    TFT.splitToWayline = function (groups) {
        const size = groups.length;
        const parts = Math.ceil(size / 25);
        const totalInParts = Math.ceil(size / parts);

        return Array(parts)
            .fill(totalInParts)
            .map((t, index) => groups.slice(index * totalInParts, (index + 1) * totalInParts));
    };

    TFT.clusteringOrders = function (orders) {
        let groups = orders.reduce((prevGroups, [order_id, lat, lng, order_time], index) => {
            order_time = new Date(order_time).getTime();

            if (!prevGroups[order_time]) prevGroups[order_time] = [];
            prevGroups[order_time].push(index);
            return prevGroups;
        }, []);

        return Object.keys(groups).map(key => groups[key]); //sortedGroups
    };

    TFT.setPointsOfInterest = function (markersData, map) {

        markersData?.forEach(markerData => {
            TFT.drawPointOfInterest(markerData, map);
        })
    };

    /** get the maximal distance between center and list of points */
    TFT.coverageRadius = function (center, points) {
        let maxD = 0;
        // we consider that's points has an origin to check
        points?.forEach((point) => {
            maxD = Math.max(TFT.measureDistance(center, point), maxD);
        });
        return maxD;
    };

    // return the near group if the point is already in radius of this groups.
    TFT.getGroupFromPoint = function (point, groups, radiusOffset = 0) {
        let indexOfPointGroup = -1;

        // we set it with the maximum value
        let nearDistance = Infinity;

        // in radius or not
        groups?.forEach(({center, radius, group}, gIndex) => {

            const distanceFromGroupCenter = TFT.measureDistance(point, center);

            if (distanceFromGroupCenter < (radius + radiusOffset) && distanceFromGroupCenter < nearDistance) {
                indexOfPointGroup = gIndex;
                nearDistance = distanceFromGroupCenter;
            }

        });

        return indexOfPointGroup;
    };

    TFT.getMapOptions = function (options) {

        const defaultMapOptions = {
            mapTypeControl: false,
            zoomControl: true,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true
        };

        options && Object.keys(options).forEach(function (key) {
            defaultMapOptions[key] = options[key] === 'true'
        });

        if (TFT.options.isPreview) {
            Object.keys(defaultMapOptions).forEach(function (key) {
                defaultMapOptions[key] = false;
            });
        }

        return defaultMapOptions;
    };

    TFT.editCustomerAddress = async function (data) {

        try {
            const response = await fetch(TFT.options.host + '/tracking/editCustomerAddress', {
                method: "POST",
                body: data
            });
            const responseData = await response.json();
            if (response.ok) {
                return responseData;
            }
            return Promise.reject(responseData);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    TFT.buildPageBlocks = async function (data) {
        const urlParams = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (urlParams.hideTrack !== null) {
            return false;
        }

        if (TFT.empty(data.plugins)) {
            return false;
        }

        if (data.plugins.hasOwnProperty('colors')) {
            const trackViewBlocs = document.querySelector(':root');
            if (trackViewBlocs) {
                const colors = data.plugins.colors;
                trackViewBlocs.style.setProperty('--tft-principal-color', colors.principal);
                trackViewBlocs.style.setProperty('--tft-secondary-color', colors.secondary);
                trackViewBlocs.style.setProperty('--tft-light-color', colors.light);
                trackViewBlocs.style.setProperty('--tft-principal-font-color', invertColor(colors.principal));
                trackViewBlocs.style.setProperty('--tft-secondary-font-color', invertColor(colors.secondary));
                trackViewBlocs.style.setProperty('--tft-light-font-color', invertColor(colors.light));
            }
        }

        const promises = [];
        if (data.plugins.hasOwnProperty('header')) {

            if (document.querySelector('#reporting-container') === null) {
                initHeader({clientAccountId: data?.account?.clientId, ...data?.plugins.header, ...data?.account?.trackActions, ...data?.order?.deliveryState});
            }

            const headerCustomization = data.plugins?.header?.customization;

            if (headerCustomization?.custom_header === 'true') {
                const headerSelector = document?.querySelector('.navbar-fixed-top_landing');
                headerSelector?.style.setProperty('--theme-header-background', headerCustomization.background_color);
                headerSelector?.style.setProperty('--theme-header-text-color', headerCustomization.font_color);
                headerSelector?.style.setProperty('--theme-header-support-phone-color', 'var(--tft-accentuation-color)');
                headerSelector?.style.setProperty('--theme-header-bottom-border', 'var(--tft-secondary-color)');
                headerSelector?.style.setProperty('--theme-header-button-bg', 'var(--tft-secondary-color)');
                headerSelector?.style.setProperty('--theme-header-button-text-color', 'var(--tft-secondary-font-color)');
                const panelReportingBackButton = document?.getElementById('tft-header-panel-reporting-back')
                panelReportingBackButton?.style.setProperty('border-color', 'var(--tft-secondary-color)');
                panelReportingBackButton?.style.setProperty('--theme-header-text-color', 'var(--tft-support2-color)');
            }

            if (!TFT.options.isPreview && !isInitClientReportingPanel) {
                promises.push(initClientReportingPanel());
                isInitClientReportingPanel = true;
            }
        }

        if (data.plugins.hasOwnProperty('shipper')) {
            promises.push(initShipper(data.plugins?.shipper));
        }

        if (data.plugins.hasOwnProperty('stopsBar')) {
            promises.push(initStopsBar(data));
        }

        if (data.plugins.hasOwnProperty('footer')) {
            if (TFT.options.isPreview === true) {
                promises.push(initFooter(data));
            } else {
                const footerCustomization = data.plugins?.footer.customization;
                const {visibility, scroll_to_footer} = footerCustomization;

                document.querySelector('footer').style.setProperty('display', visibility === "false" ? 'none' : '');

                const footerHeight = document.querySelector('footer').offsetHeight;
                if (scroll_to_footer === 'false') {
                    document.querySelector("#content")?.style.setProperty('height', window.innerHeight - document.querySelector('.navbar-fixed-top_landing')?.offsetHeight - footerHeight + 'px');
                } else {
                    document.querySelector("#content").style.height = window.innerHeight - document.querySelector('.navbar-fixed-top_landing')?.offsetHeight + 'px';
                }
            }
        }

        Promise.all(promises).then(() => {
            document.getElementById('tft-track-view-blocks')?.insertAdjacentHTML('beforeend', `<div id="Proxipick_ATF_320x50"></div>`);
            initDeliveryBox(data);
            const {updateDropOffPoint, updateSafePlace} = data.account.trackActions?.deliveryBox?.deliveryContextArea || {};
            TFT.updateDropOffPoint = updateDropOffPoint;
            TFT.updateSafePlace = updateSafePlace;

            document.getElementById('tft-track-view-blocks')?.style.setProperty('visibility', 'visible');
        });
    }

    TFT.empty = function (value) {
        let undef
        let key
        let i
        let len
        const emptyValues = [undef, null, false, 0, '', '0']
        for (i = 0, len = emptyValues.length; i < len; i++) {
            if (value === emptyValues[i]) {
                return true
            }
        }
        if (typeof value === 'object') {
            for (key in value) {
                if (value.hasOwnProperty(key)) {
                    return false
                }
            }
            return true
        }
        return false
    }

    TFT.initiateDrawer = function () {
        const mapDom = document.querySelector(TFT.options.target + " #tft-track-map-container");
        if (mapDom === null) {
            return false;
        }

        if (document.getElementById('tft-track-map-drawer') !== null) {
            return true;
        }

        mapDom?.insertAdjacentHTML('beforeend', `
            <div id="tft-track-map-drawer" class="sidebar empty">
                <div>
                    <div class="tft-drawer-close" data-container="body" data-trigger="hover" data-placement="right"
                    title="Close the side info">
                        <svg viewBox="-125 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M137.4 406.6l-128-127.1C3.125 272.4 0 264.2 0 255.1s3.125-16.38 9.375-22.63l128-127.1c9.156-9.156 22.91-11.9 34.88-6.943S192 115.1 192 128v255.1c0 12.94-7.781 24.62-19.75 29.58S146.5 415.8 137.4 406.6z"/></svg>                 </div>
                    <div class="drawer-content scrollable-y"></div>
                </div>
            </div>
        `);

        const mapDrawer = document.getElementById('tft-track-map-drawer');
        mapDrawer.querySelector('.tft-drawer-close').addEventListener('click', function () {
            mapDrawer.classList.remove('active');
            mapDrawer.classList.add('empty');
        });
    }

    TFT.getAgentPopup = function (popups, marker) {

        if (!popups || (typeof popups.agentPopup === 'undefined') || (popups && !TFT.isTrue(popups.agentPopup.visibility))) {
            return null;
        }

        const agentPopup = {
            background_color: '',
            font_color: '',
            border_color: ''
        };

        if (popups && popups.agentPopup.background_color) {
            agentPopup.background_color = popups.agentPopup.background_color;
        }

        if (popups && popups.agentPopup.font_color) {
            agentPopup.font_color = popups.agentPopup.font_color;
        }

        if(popups && popups.agentPopup.border_color) {
            agentPopup.border_color = popups.agentPopup.border_color;
        }

        if (marker && marker.height) {
            agentPopup.offset = -marker.height + 5 || 62;
        }

        return agentPopup;
    }

    TFT.getDeliveryOptionMapping = function (eventId, deliveryDate) {
        if (!eventId) {
            return;
        }

        switch (eventId) {
            case TFT.EVENT_DEPRES:
            case TFT.EVENT_DEPRESFDS:
            case TFT.EVENT_DEPRESENOT:
                return "Livraison prévue en <span class='bold-detail-delivery-option'>lieu sûr</span>.<span class='btn-detail-delivery-option'>voir</span>";
            case TFT.EVENT_LIVPR:
            case TFT.EVENT_LIVPRFDS:
            case TFT.EVENT_LIVPRENOT:
            case TFT.EVENT_RNDEND_LIVPR:
                return "Livraison prévue en <span class='bold-detail-delivery-option'>Relais GLS</span>.<span class='btn-detail-delivery-option'>voir</span>";
            case TFT.EVENT_PAQ:
                return 'Récupération du colis directement au dépôt GLS';
            case TFT.EVENT_RPGM0:
                const date = new Date(deliveryDate).toLocaleString("fr-FR", {
                    timeZone: "Europe/Paris",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                });
                return `Livraison prévue le <span class="bold-detail-delivery-option" style="display: inline-block;">${date?.replace(/\//g, '-')}</span>.<span class='btn-detail-delivery-option'>voir</span>`;
            default:
                return null;
        }
    }

    TFT.addOverflowToMap = function (visibility) {
        if (visibility === false) {
            document.getElementById('tft-track-map-overflow')?.remove();
        } else {
            const mapDom = document.getElementById('tft-track-map-container');
            if (!mapDom || !document.getElementById('tft-track-map-overflow')) {
                return
            }
            mapDom?.insertAdjacentHTML('afterend', `<div id="tft-track-map-overflow"></div>`);
        }
    }

    async function initClientReportingPanel() {
        await TFT.importCss(TFT.options.host + '/public/app/' + TFT.buildType + '/front/css/client-header-reporting-panel.css?' + TFT.assetVersion);
        await TFT.importJs(TFT.options.host + '/public/app/' + TFT.buildType + '/front/js/client-header-reporting-panel.js?' + TFT.assetVersion, 'module');

        clientHeaderReportingPanel.init();
    }

    function initHeader(data) {
        const {
            accountName,
            accountLogo,
            accountPhone,
            customization,
            eventId,
            header: {
                supportType,
                supportText,
                updateMissedParcelDelivery,
                updateNoSituationSuited,
                updateNotWaitingParcel,
            } = {},
            deliveryBox: {
                deliveryContextArea,
                consigneeInformationArea,
                scheduleArea
            } = {}
        } = data;

        const headerActions = [
            deliveryContextArea?.updateDropOffPoint || false,
            deliveryContextArea?.updateSafePlace || false,
            consigneeInformationArea?.updateDeliveryAddress || false,
            consigneeInformationArea?.updatePhoneNumber || false,
            consigneeInformationArea?.updateAccessInformation || false,
            scheduleArea?.updateDeliverySlot || false,
            updateMissedParcelDelivery || false,
            updateNoSituationSuited || false,
            updateNotWaitingParcel || false,
        ]

        let clientReportingView = '', headerTitleView = '';

        const headerContentAvailability = headerActions.some(action => action) || Boolean(eventId)

        if (accountPhone && supportType === 'showSupportNumber') {
            const phone = accountPhone.trim().match(/.{1,2}/g).join(' ');

            headerTitleView = `
                <div class="sep"></div>
                <div class="tft-service-client-phone">Service client : <a href="tel:${accountPhone.trim()}" class="support-phone" style="font-weight:600;"> ${phone}</a></div>
            `;
        } else if (headerContentAvailability && supportType !== 'nothing') {
            clientReportingView = `
                <button id="panel-reporting" class="track-header-button ${(supportType === 'showSupportIcon') ? 'track-header-button-icon' : ''}" style="min-width: 120px; display:block; flex-shrink: 0;"
                        data-cy="panel-reporting">
                    ${supportType === 'showButtonText' ? supportText : '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 35" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><g transform="translate(-367-85)"><path d="M33.625,17.5C33.625,9.645508,26.078906,2.28125,18,2.28125s-15.625,7.364258-15.625,15.21875v2.734375c0,.90918-.752344,1.640625-1.6875,1.640625s-.6875-.731445-.6875-1.640625L0,17.5C0,7.833984,8.057812,0,18,0s18,7.833984,18,17.5v9.850586c0,3.322266-2.770312,6.015625-6.194531,6.015625L24.05,33.359375C23.466406,34.336914,22.376563,35,21.125,35h-1.25c-1.863281,0-4.375-.469727-4.375-2.28125s1.511719-3.28125,3.375-3.28125h2.25c1.251563,0,2.341406.663086,2.925,1.640625l5.7625.006836c1.553906,0,3.8125-2.223633,3.8125-3.734375v-9.850586Zm-30,1.09375c0-2.413086.017969-4.375,2.5-4.375h1.125c1.244531,0,1.25.977539,1.25,2.1875v7.65625c0,1.209961-.005469,2.1875-1.25,2.1875h-1.125c-2.482031,0-2.5-1.961914-2.5-4.375v-3.28125Zm25.125-4.375h1.125c2.482031,0,2.5,1.961914,2.5,4.375v3.28125c0,2.413086-.017969,4.375-2.5,4.375h-1.125c-1.244531,0-1.25-.977539-1.25-2.1875v-7.65625c0-1.209961.005469-2.1875,1.25-2.1875Z" transform="translate(367 85)" fill="#22a611" stroke="#fff" stroke-opacity="0" stroke-miterlimit="1"/></g></svg>'}
                </button>
                <button id="tft-header-panel-reporting-back" class="track-header-button" style="display: none;min-width: 120px;">
                    Annuler
                </button>
            `;
        }

        let headerView = `
            <div class='navbar-fixed-top_landing' style="pointer-events: auto;min-height: inherit;">
               <div class='dsc_01 header-box ${(supportType === 'nothing' || !headerContentAvailability ) ? 'logo-only' : ''}'>
                   <a href='#' title='${accountName}' >
                       <img src='${accountLogo}' alt='${accountName}'>
                   </a>
                   ${clientReportingView}
                   ${headerTitleView}
                   <button id="tft-header-panel-reporting-back" class="track-header-button" style="display: none;min-width: 120px;">
                    Annuler
                </button>
               </div>
               <div style="background-color: #FFFFFF; color:#000">
                <div id="reporting-container"></div>
                <div id="header-reporting-alert-message"></div>
               </div>
            </div>
        `;

        document.querySelector('#tracking-page')?.insertAdjacentHTML('afterbegin', headerView);

        if (customization && customization.visibility === 'false') {
            document.querySelector('.navbar-fixed-top_landing')?.style.setProperty('display', 'none');
        }
    }

    function initDeliveryBox(data) {
        //language=HTML
        const deliveryBoxView = `
            <div id="tft-delivery-box__container">
                <div class="tft-delivery-box-float-buttons"></div>
                <div id="tft-delivery-box">
                    <section id="tft-schedule-area"></section>
                    <section id="tft-delivery-context-area"></section>
                    <section id="tft-consignee-information-area"></section>
                </div>
            </div>
        `;

        document.querySelector('#tft-track-view-blocks')?.insertAdjacentHTML('beforeend', deliveryBoxView);

        const trackMapDeliveryBox = new TrackMapDeliveryBox(data);
        trackMapDeliveryBox.init();
    }

    async function initShipper(data) {
        const {name, logo, customization} = data;

        if (logo) {
            const shipperView = `
                <div id='shipper-logo-panel' style="display: block">
                    <div id='view-block-inner'>
                        <img src="${logo}" alt="${name}" style="visibility: hidden">
                    </div>
                </div>`;

            document.getElementById('tft-track-view-blocks')?.insertAdjacentHTML('beforeend', shipperView);

            const shipperLogoImg = document.getElementById('view-block-inner')?.querySelector('img');
            if (customization) {
                const backgroundShipper = isNaN(parseInt(customization?.background_color)) ? customization?.background_color : "rgba(255,255,255," + customization?.background_color / 100 + ")";
                document.getElementById('shipper-logo-panel')?.style.setProperty('background-color', backgroundShipper);
                shipperLogoImg.onload = () => {
                    const width = shipperLogoImg.offsetWidth * (customization?.size / 100) || shipperLogoImg.offsetWidth;
                    const height = shipperLogoImg.offsetHeight * (customization?.size / 100) || shipperLogoImg.offsetHeight;
                    shipperLogoImg.style.width = `${width}px`;
                    shipperLogoImg.style.height = `${height}px`;
                    shipperLogoImg.style.visibility = 'visible';
                };
            } else {
                shipperLogoImg.style.visibility = 'visible';
            }
        }
    }

    async function initFooter(data) {
        const {customization} = data.plugins?.footer;

        let footerView = `<footer id="tft-footer" class="tft-light-background-color" style="padding: 0" data-cy="tft-footer">
                <section class="footer-social">
                    <ul class="footer-social-list">
                       <li><a target="_blanc" href="https://www.facebook.com/tousfacteurs"> <i class="fab fa-facebook fa-lg tft-support2-color"></i> </a></li>
                       <li><a href=""><i class="fab fa-twitter fa-lg tft-support2-color"></i></a></li>
                       <li><a target="_blank" href="https://www.linkedin.com/company/tousfacteurs"><i class="fab fa-linkedin fa-lg tft-support2-color"></i></a></li>
                    </ul>
                </section>

                <section class="footer-links">
                    <a class="tft-accentuation-color" target="_blank" href="https://www.tousfacteurs.com/mentions-legales/">Mentions légales</a>
                    <span class="tft-accentuation-color">-</span>
                    <a class="tft-accentuation-color" target="_blank" href="https://www.tousfacteurs.com/cgu-cgv/">Conditions générales</a>
                    <span class="tft-accentuation-color">-</span>
                    <a class="tft-accentuation-color" target="_blank" href="https://www.tousfacteurs.com/politique-de-confidentialite/">Politique de Confidentialité</a>
                </section>

                <section class="footer-copyright tft-support2-color">Copyright &#169; ${new Date().getFullYear()} - Tousfacteurs SAS | Tous Droits Réservés.</section>
            </footer>
        `;

        document.querySelector('#track-container')?.insertAdjacentHTML('afterend', footerView);

        const footerHeight = document.querySelector('footer').offsetHeight;
        document.querySelector("#track-container")?.style.setProperty('height', `calc(100vh - ${footerHeight}px)`);
        document.querySelector("#tft-track-view-blocks")?.style.setProperty('height', 'inherit');

        if (customization && customization.visibility === 'false') {
            document.querySelector('footer')?.style.setProperty('display', 'none');
            document.querySelector("#track-container")?.style.setProperty('height', '100vh');
        }

        if (customization && customization.scroll_to_footer === 'true') {
            document.querySelector("#track-container")?.style.setProperty('height', '100vh');
        }
    }

    async function initStopsBar(data) {

        const {customization, stops} = data.plugins?.stopsBar;

        if (!customization || !stops || !data?.order?.isInTransit) {
            return;
        }

        stops.sort((a, b) => b - a);
        const limitedStops = customization?.number_stops === 0 ? stops : stops.slice(0, customization?.number_stops);

        const stopsPoints = limitedStops.map(time => `
        <div class="stopsbar-point">
            <div class="stopsbar-popup stopsbar-popup-past">
                <div class="stopsbar-content">
                    <div class="stopsbar-text" > ${time === limitedStops[0] ? 'Dernier stop' : 'Stop précédent'}</div>
                    <div class="stopsbar-count" >${Math.floor((Date.now() / 1000 - time) / 60)} min</div>
                </div>
                 <div class="stopsbar-pop-close-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 300 300" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
                        <path transform="matrix(.99002 0 0 .99007 150 150)" fill="none" stroke=${customization.popup_text_color} stroke-width="15" stroke-linecap="round" stroke-miterlimit="1" d="m-150-150 300 300m0-300-300 300"/>
                    </svg>
                </div>
            </div>
        </div>
        `);
        const isRight = customization.position === "right";
        const closeButton = customization.closable === "true" ? `<div class="stopsbar-close">&times;</div>` : "";

        const stopsBarTemplate = `${closeButton}<div class="stopsbar-wrapper ${isRight ? 'stopsbar-right' : 'stopsbar-left'}" >
        <div class="stopsbar" >
            <div class="stopsbar-gauge" >
                ${stopsPoints.join('')}
            </div>
        </div>
    </div>`;

        let stopsBarElement = document.createElement("div");
        stopsBarElement.innerHTML = stopsBarTemplate;
        stopsBarElement.classList.add('stopsbar-container');

        if (isRight) {
            stopsBarElement.style.setProperty('right', window.innerWidth <= 768 ? '12px' : '60px');
        } else {
            stopsBarElement.style.setProperty('left', '12px');
        }

        // update style
        stopsBarElement.style.setProperty("--stopsbar-start-color", customization.start_color);
        stopsBarElement.style.setProperty("--stopsbar-end-color", customization.end_color);
        stopsBarElement.style.setProperty("--stopsbar-fill-color", customization.fill_color);
        stopsBarElement.style.setProperty("--stopsbar-popup-text-color", customization.popup_text_color);
        stopsBarElement.style.setProperty("--stopsbar-remaining-text-color", customization.remaining_text_color);
        stopsBarElement.style.setProperty("--stopsbar-time-text-color", customization.time_text_color);
        stopsBarElement.style.setProperty("--stopsbar-popup-background-color", customization.pop_up_background_color);
        stopsBarElement.style.setProperty("--stopsbar-popup-border-color", customization.pop_up_border_color);


        // click on stops bar
        stopsBarElement.addEventListener("click", function (event) {
            const target = event.target;

            const stopbarPoint = target.closest('.stopsbar-point');

            if (stopbarPoint?.classList.contains("stopsbar-point")) {
                event.stopPropagation();

                const popupElement = stopbarPoint.querySelector(".stopsbar-popup");
                if (!popupElement) return;

                // clear timeout
                clearTimeout(popupElement.delayTimeout);
                if (popupElement.classList.contains("fade")) {
                    popupElement.classList.remove("fade");
                } else {
                    popupElement.classList.add("fade");
                }
            } else if (target?.classList.contains("stopsbar-close")) {
                event.stopPropagation();

                const wrapper = event.currentTarget.querySelector(".stopsbar-wrapper");

                if (wrapper.classList.contains("stopsbar-closed")) {
                    wrapper.classList.remove("stopsbar-closed");
                    target.classList.remove("stopsbar-closed");
                } else {
                    wrapper.classList.add("stopsbar-closed");
                    target.classList.add("stopsbar-closed");
                }
            }
        });

        if (customization?.autohide === "true") {
            const delayTime = (customization?.autohide_delay ?? 0) * 1000;

            stopsBarElement.querySelectorAll(".stopsbar-popup")?.forEach((popupElement) => {
                popupElement.delayTimeout = setTimeout(() => {
                    popupElement.classList.add("fade");
                }, delayTime);
            });

        }
        document.getElementById('tft-track-view-blocks')?.prepend(stopsBarElement);
    }

    TFT.importCss = function (url) {
        return new Promise((resolve) => {
            const style = document.createElement('link');
            style.href = url;
            style.type = 'text/css';
            style.rel = 'stylesheet';
            style.onload = () => {
                resolve();
            };

            const head = document.getElementsByTagName('head')[0];
            head.appendChild(style);
        });
    }

    TFT.importJs = function (url, type = 'text/javascript') {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = url;
            script.type = ['text/javascript', 'module'].includes(type) ? type : 'text/javascript';
            script.onload = () => {
                resolve();
            };

            const head = document.getElementsByTagName('head')[0];
            head.insertBefore(script, null);
        });
    }

    async function fetchDataJSON() {

        if (TFT.options.orderKey === null && TFT.options.shipperId === null && TFT.options.clientCode === null) {
            throw new Error('One of the options (orderKey, shipperId, clientCode) is required');
        }

        const isPreviewParam = (TFT.options.isPreview ? '&isPreview=true' : '');

        try {
            if (!TFT.options.orderKey) {
                let trackUrl = TFT.options.host + TFT.options.endpoint;
                trackUrl += (TFT.options.shipperId !== null) ? '?shipperId=' + TFT.options.shipperId : '?clientCode=' + TFT.options.clientCode;
                trackUrl += isPreviewParam;

                const response = await fetch(trackUrl);
                let apiResponse = await response.json();

                if (TFT.options.isPreview) {
                    return apiResponse;
                }

                if (!response.ok) {
                    apiResponse = {};
                }

                if (typeof apiResponse.order === "undefined") {
                    apiResponse.order = {};
                }

                if (typeof apiResponse.plugins === "undefined") {
                    apiResponse.plugins = {};
                }

                if (!apiResponse.plugins.hasOwnProperty('stopsBar')) {
                    apiResponse.plugins.stopsBar = {};
                }

                apiResponse.order.isInTransit = TFT.options.data.currentDelivery.isInTransit;
                apiResponse.order.customer = {
                    latitude: TFT.options.data.currentDelivery.latitude,
                    longitude: TFT.options.data.currentDelivery.longitude
                };

                if (TFT.options.data.pastDeliveries.length) {

                    TFT.options.data.pastDeliveries.sort((a, b) => a.timestamp - b.timestamp);

                    apiResponse.postman = TFT.options.data.pastDeliveries.pop();

                    apiResponse.points = [];
                    apiResponse.plugins.stopsBar.stops = [];
                    TFT.options.data.pastDeliveries.forEach((value, index) => {
                        apiResponse.points.push([index, value.latitude, value.longitude, value.timestamp]);
                        apiResponse.plugins.stopsBar.stops.push(value.timestamp);
                    });
                    apiResponse.plugins.stopsBar.stops.push(apiResponse.postman.timestamp);
                }

                return apiResponse;
            } else {
                const response = await fetch(TFT.options.host + TFT.options.endpoint + '?orderKey=' + TFT.options.orderKey + isPreviewParam);
                return await response.json();
            }
        } catch (error) {
                console.error(error)
        }

        return null;
    }

    TFT.postmanInfoBubbleContent = function (postman) {
        const postmanLastPositionAt = Math.floor((Date.now() / 1000 - postman.timestamp) / 60);

        return (`<div class="info_bubble_custom_content">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"/>
                </svg>
            </div>
            <div>Votre livreur était<br> ici il y a <strong>${postmanLastPositionAt} min</strong></div>
        </div>`);
    }

    TFT.init = async function (options) {
        if (!document.body) {
            window.addEventListener("DOMContentLoaded", () => {
                init(options);
            });
            return;
        }

        Object.assign(TFT.options, options);

        if (document.querySelector(TFT.options.target) === null) {
            throw new Error('Track "target" dom is not specified');
        }

        if (TFT.options.host === null) {
            throw new Error('Track "host" is not specified');
        }
        TFT.options.host = TFT.options.host.replace(/\/$/g, '');

        const mapProviderOption = TFT.options.map.provider;
        const mapProvider = mapProviderOption ? mapProviderOption.toLowerCase() : null;

        if (!['google', 'here'].includes(mapProvider)) {
            throw new Error('Only GOOGLE and HERE are supported as map provider');
        }

        document.querySelector(TFT.options.target).innerHTML = '<div class="tft-loading" data-cy="tft-loading"><div></div><div></div><div></div></div>';

        await TFT.importJs(TFT.options.host + '/public/app/' + TFT.buildType + '/front/js/track-map-' + mapProvider + '.js?' + TFT.assetVersion, 'module');

        // load google or here
        if (typeof TFT.importMapAsset !== 'function') {
            throw new Error('Error loading map script');
        }

        const importMapAssetPromise = TFT.importMapAsset();
        await fetchDataJSON().then(json => {
            if (json === null) {
                document.querySelector(TFT.options.target).innerHTML = 'Error loading data ...';
                return false;
            }

            if (typeof window.dataLayer !== 'undefined' && json?.account) {
                const lrsActions = json?.account?.trackActions?.deliveryBox?.deliveryContextArea || {};
                const trueLrsActions = Object.keys(lrsActions).filter(key => Boolean(lrsActions[key]) === true);

                window.dataLayer.push(
                    {
                        'trajectoryMode': json?.trajectoryType || '',
                        'lrsActions': trueLrsActions.join(',')
                    }
                );
            }

            TFT.headerData = {header: json.plugins?.header, order: json?.order, account: json?.account, orderAccessInformations: json.plugins?.orderAccessInformations};

            document.querySelector(TFT.options.target).innerHTML = '<div id="tft-track-map-container"></div><div id="tft-track-view-blocks"></div><div class="modal fade" id="tft-track-delivery-detail" tabindex="-1" role="dialog" aria-hidden="true"></div>';

            TFT.buildPageBlocks(json);
            importMapAssetPromise.then(() => TFT.buildMap(json));
        });
    }

    Object.assign(window, {TFT: TFT});
})(window);

/**
 * DBSCAN - Density based clustering
 *
 * @author Lukasz Krawczyk <contact@lukaszkrawczyk.eu>
 * @copyright MIT
 */

/**
 * DBSCAN class construcotr
 * @constructor
 *
 * @param {Array} dataset
 * @param {number} epsilon
 * @param {number} minPts
 * @param {function} distanceFunction
 * @returns {DBSCAN}
 */
function DBSCAN(dataset, epsilon, minPts, distanceFunction) {
    /** @type {Array} */
    this.dataset = [];
    /** @type {number} */
    this.epsilon = 1;
    /** @type {number} */
    this.minPts = 2;
    /** @type {function} */
    this.distance = this._euclideanDistance;
    /** @type {Array} */
    this.clusters = [];
    /** @type {Array} */
    this.noise = [];

    // temporary variables used during computation

    /** @type {Array} */
    this._visited = [];
    /** @type {Array} */
    this._assigned = [];
    /** @type {number} */
    this._datasetLength = 0;

    this._init(dataset, epsilon, minPts, distanceFunction);
}

/******************************************************************************/
// public functions

/**
 * Start clustering
 *
 * @param {Array} dataset
 * @param {number} epsilon
 * @param {number} minPts
 * @param {function} distanceFunction
 * @returns {undefined}
 * @access public
 */
DBSCAN.prototype.run = function (dataset, epsilon, minPts, distanceFunction) {
    this._init(dataset, epsilon, minPts, distanceFunction);

    for (let pointId = 0; pointId < this._datasetLength; pointId++) {
        // if point is not visited, check if it forms a cluster
        if (this._visited[pointId] !== 1) {
            this._visited[pointId] = 1;

            // if closest neighborhood is too small to form a cluster, mark as noise
            const neighbors = this._regionQuery(pointId);

            if (neighbors.length < this.minPts) {
                this.noise.push(pointId);
            } else {
                // create new cluster and add point
                const clusterId = this.clusters.length;
                this.clusters.push([]);
                this._addToCluster(pointId, clusterId);

                this._expandCluster(clusterId, neighbors);
            }
        }
    }

    return this.clusters;
};

/******************************************************************************/
// protected functions

/**
 * Set object properties
 *
 * @param {Array} dataset
 * @param {number} epsilon
 * @param {number} minPts
 * @param {function} distance
 * @returns {undefined}
 * @access protected
 */
DBSCAN.prototype._init = function (dataset, epsilon, minPts, distance) {

    if (dataset) {

        if (!(dataset instanceof Array)) {
            throw Error('Dataset must be of type array, ' +
                typeof dataset + ' given');
        }

        this.dataset = dataset;
        this.clusters = [];
        this.noise = [];

        this._datasetLength = dataset.length;
        this._visited = new Array(this._datasetLength);
        this._assigned = new Array(this._datasetLength);
    }

    if (epsilon) {
        this.epsilon = epsilon;
    }

    if (minPts) {
        this.minPts = minPts;
    }

    if (distance) {
        this.distance = distance;
    }
};

/**
 * Expand cluster to closest points of given neighborhood
 *
 * @param {number} clusterId
 * @param {Array} neighbors
 * @returns {undefined}
 * @access protected
 */
DBSCAN.prototype._expandCluster = function (clusterId, neighbors) {

    /**
     * It's very important to calculate length of neighbors array each time,
     * as the number of elements changes over time
     */
    for (let i = 0; i < neighbors.length; i++) {
        const pointId2 = neighbors[i];

        if (this._visited[pointId2] !== 1) {
            this._visited[pointId2] = 1;
            const neighbors2 = this._regionQuery(pointId2);

            if (neighbors2.length >= this.minPts) {
                neighbors = this._mergeArrays(neighbors, neighbors2);
            }
        }

        // add to cluster
        if (this._assigned[pointId2] !== 1) {
            this._addToCluster(pointId2, clusterId);
        }
    }
};

/**
 * Add new point to cluster
 *
 * @param {number} pointId
 * @param {number} clusterId
 */
DBSCAN.prototype._addToCluster = function (pointId, clusterId) {
    this.clusters[clusterId].push(pointId);
    this._assigned[pointId] = 1;
};

/**
 * Find all neighbors around given point
 *
 * @param {number} pointId,
 * @returns {Array}
 * @access protected
 */
DBSCAN.prototype._regionQuery = function (pointId) {
    const neighbors = [];

    for (let id = 0; id < this._datasetLength; id++) {
        const dist = this.distance(this.dataset[pointId], this.dataset[id]);
        if (dist < this.epsilon) {
            neighbors.push(id);
        }
    }

    return neighbors;
};

/******************************************************************************/
// helpers

/**
 * @param {Array} a
 * @param {Array} b
 * @returns {Array}
 * @access protected
 */
DBSCAN.prototype._mergeArrays = function (a, b) {
    const len = b.length;

    for (let i = 0; i < len; i++) {
        const P = b[i];
        if (a.indexOf(P) < 0) {
            a.push(P);
        }
    }

    return a;
};

/**
 * Calculate euclidean distance in multidimensional space
 *
 * @param {Array} p
 * @param {Array} q
 * @returns {number}
 * @access protected
 */
DBSCAN.prototype._euclideanDistance = function (p, q) {
    let sum = 0;
    let i = Math.min(p.length, q.length);

    while (i--) {
        sum += (p[i] - q[i]) * (p[i] - q[i]);
    }

    return Math.sqrt(sum);
};

if (typeof module !== 'undefined' && module.exports) {
    module.exports = DBSCAN;
}
