import BottomSheet from './BottomSheet.js';

class TrackMapDeliveryBox {
    constructor(data) {
        this.data = data;
        this.deliveryBoxContainer = data.deliveryBoxContainer ?? document.getElementById('tft-delivery-box__container');
        this.floatButtonsContainer = document.querySelector(`#${this.deliveryBoxContainer.id} .tft-delivery-box-float-buttons`);
        this.deliveryBox = document.querySelector(`#${this.deliveryBoxContainer.id} #tft-delivery-box`);

        this.consigneeInformationArea = this.deliveryBox?.querySelector('#tft-consignee-information-area');
        this.deliveryContextArea = this.deliveryBox?.querySelector('#tft-delivery-context-area');
        this.scheduleArea = this.deliveryBox?.querySelector('#tft-schedule-area');
    }

    init() {
        const {
            consigneeInformationData,
            deliveryContextData,
            scheduleData,
            floatButtonsData
        } = this.prepareDeliveryBoxData(this.data);

        const consigneeInformationView = this.buildConsigneeInformationArea(consigneeInformationData);
        const deliveryContextAreaView = this.buildDeliveryContextArea(deliveryContextData);
        const scheduleAreaView = this.buildScheduleArea(scheduleData);
        const floatButtonsView = this.buildFloatButtonsArea(floatButtonsData);

        if(!consigneeInformationView && !deliveryContextAreaView && !scheduleAreaView) {
            this.deliveryBoxContainer?.remove();
            return;
        }

        this.consigneeInformationArea?.insertAdjacentHTML('beforeend', consigneeInformationView);
        this.deliveryContextArea?.insertAdjacentHTML('beforeend', deliveryContextAreaView);
        this.scheduleArea?.insertAdjacentHTML('beforeend', scheduleAreaView);
        this.floatButtonsContainer?.insertAdjacentHTML('beforeend', floatButtonsView);

        this.deliveryBoxContainer?.addEventListener('click', (event) => {
            const {action} = event?.target?.dataset || {};
            if (action) {
                window?.clientHeaderReportingPanel?.mappingDeliveryBoxAndHeaderAction(action);
            }
        });

        const visibleHeightOnClose = this.deliveryBox?.querySelector('.visible-areas-schedule__onclose')?.offsetHeight || 75;
        this.floatButtonsContainer.style.setProperty('bottom', visibleHeightOnClose + 'px');

        window.bottomSheet = new BottomSheet({
            id: `#${this.deliveryBoxContainer.id} #tft-delivery-box`,
            config: {
                draggableAreaSelector: '.tft-draggable-area',
                visibleHeightOnClose,
                image: this.deliveryBox?.querySelector('.tft-delivery-proof__image')
            }
        });
        window.bottomSheet?.init();
        if (TFT.options.isPreview === true && !TFT.options.isExternal) {
            setTimeout(function () {
                window.bottomSheet.close();
            }, 5000);
        }
    }

    destroy() {
        this.deliveryBox.innerHTML = `
            <section id="tft-schedule-area"></section>
            <section id="tft-delivery-context-area"></section>
            <section id="tft-consignee-information-area"></section>
        `;

        this.floatButtonsContainer.innerHTML = "";
    }

    prepareDeliveryBoxData(data) {
        const {
            trajectoryType,
            account: {clientId, customerServiceEmail = '', trackActions} = {},
            order: {status, currentStopPosition, trackingZoneNotReached} = {},
            plugins: {orderInfo = {}, orderAccessInformations = {}, orderSummary = {}} = {}
        } = data || {};

        const {
            customer: {firstname = '', lastname = '', shippingAddress = ''} = {},
            deliveryDetails: {pictureProof, shippingMode = ''} = {}
        } = orderInfo;

        const {additionalInformations = '', customerPhone = ''} = orderAccessInformations;

        const {dateSlot = '', timeSlot = '', orderNumber = ''} = orderSummary;

        const {
            deliveryContextArea: deliveryContextAreaActions = {},
            consigneeInformationArea: consigneeInformationActions = {},
            scheduleArea: scheduleAreaActions = {},
            deliveryContextArea: deliveryContextActions = {},
        } = trackActions?.deliveryBox || {};

        const consigneeInformationData = {
            name: `${firstname || ''} ${lastname || ''}`,
            shippingAddress,
            phone: customerPhone?.trim() || '',
            additionalInformations: additionalInformations?.trim() || '',
            consigneeInformationActions,
        };

        const deliveryContextData = {
            status,
            clientId,
            trajectoryType,
            currentStopPosition,
            pictureProof,
            shippingMode,
            customerServiceEmail,
            deliveryContextAreaActions,
            trackingZoneNotReached
        };

        const floatButtonsData = {
            consigneeInformationActions,
            scheduleAreaActions,
            deliveryContextActions
        }

        const scheduleData = {
            orderNumber,
            dateSlot,
            timeSlot,
            scheduleAreaActions,
        }

        return {
            floatButtonsData,
            consigneeInformationData,
            deliveryContextData,
            scheduleData
        }
    }

    buildConsigneeInformationArea(data) {
        const {
            name = '',
            shippingAddress = '',
            phone = '',
            additionalInformations = '',
            consigneeInformationActions: {
                showAccessInformation,
                showDeliveryAddress,
                showPhoneNumber,
                showRecipientFullName,
                updateAccessInformation,
                updateDeliveryAddress,
                updatePhoneNumber,
            } = {}
        } = data;

        const isEditable = [updateAccessInformation, updateDeliveryAddress, updatePhoneNumber].some(action => action);

        if (!isEditable && !showAccessInformation && !showDeliveryAddress && !showPhoneNumber && !showRecipientFullName) {
            return '';
        }

        let additionalInfoMissingMessage = '';

        if (showPhoneNumber && !phone.trim()) {
            additionalInfoMissingMessage = (showAccessInformation && !additionalInformations) ? "N° de téléphone, Code d'accès, N° d'interphone manquants" : "N° de téléphone manquant";
        } else if (showAccessInformation && !additionalInformations) {
            additionalInfoMissingMessage = "Code d'accès, N° d'interphone manquants";
        }

        return `
             <div class="tft-consignee-information-area__container">
                ${showRecipientFullName && name ? `<div class="tft-consignee-information__name"> ${name} </div>` : ''}
                ${showDeliveryAddress && shippingAddress ? `<div class="tft-consignee-information__address">${shippingAddress}</div>` : ''}
                ${showPhoneNumber && phone ? `<div class="tft-consignee-information__phone">${phone}</div>` : ''}
                ${showAccessInformation && additionalInformations ? `<div class="tft-consignee-existed-information__additional">${additionalInformations}</div>` : ''}
                ${additionalInfoMissingMessage ? `<div class="tft-consignee-information__additinal">${additionalInfoMissingMessage}</div>` : ''}
                ${isEditable ? '<button id="edit-consignee-information-button" class="track-header-button" data-action="updateConsigneeInformation">Modifier mes informations</button>' : ''}
             </div>
        `
    }

    buildDeliveryContextArea(data) {
        const {
            status,
            clientId,
            trajectoryType,
            currentStopPosition,
            pictureProof,
            shippingMode,
            customerServiceEmail,
            deliveryContextAreaActions: {showDeliveryDetails, updateDropOffPoint, updateSafePlace},
            trackingZoneNotReached
        } = data;

        if (typeof status !== 'number' && !status) {
            return '';
        }

        const clockIcon = `
        <?xml version="1.0" encoding="utf-8"?>
        <svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="45px" height="45px" xmlns="http://www.w3.org/2000/svg">
          <g transform="matrix(1 0 0 1 -335 -178 )">
            <path d="M 21 22.4208984375  L 21 10.546875  C 20.390625 9.3779296875  21.3310546875 8.4375  22.5 8.4375  C 23.6689453125 8.4375  24.609375 9.3779296875  24 10.546875  L 24 21.375  L 32.1064453125 26.3671875  C 33.0732421875 27.017578124999996  33.3369140625 28.3271484375  32.6162109375 29.2939453125  C 32.044921875 30.2607421875  30.7353515625 30.5244140625  29.7685546875 29.803710937500004  L 21.3310546875 24.178710937500004  C 20.7421875 23.8623046875  20.390625 23.203125  21 22.4208984375  Z M 0 22.5  C 0 10.072265625  10.072265625 0  22.5 0  C 34.927734375 0  45 10.072265625  45 22.5  C 45 34.927734375  34.927734375 45  22.5 45  C 10.072265625 45  0 34.927734375  0 22.5  Z M 22.5 4.21875  C 12.4013671875 4.21875  4.21875 12.4013671875  4.21875 22.5  C 4.21875 32.5986328125  12.4013671875 40.78125  22.5 40.78125  C 32.5986328125 40.78125  40.78125 32.5986328125  40.78125 22.5  C 40.78125 12.4013671875  32.5986328125 4.21875  22.5 4.21875  Z " fill-rule="nonzero" fill="#000000" stroke="none" transform="matrix(1 0 0 1 335 178 )" />
          </g>
        </svg>`;

        const waitingOrAssignedStateView = `
            <div class="tft-delivery-context__status">
                <div class="tft-delivery-context__message">
                    Recevez des mises à jour de votre livraison en revenant sur cette page.
                </div>
                <div class="tft-delivery-context__icon">
                    ${clockIcon}
                </div>
            </div>
        `;

        const inTransitStateView = this.displayInTransitStateView({
            clientId,
            trajectoryType,
            currentStopPosition,
            trackingZoneNotReached
        });

        const deliveredStateView = this.displayDeliveredStateView({
            showDeliveryDetails,
            pictureProof,
            shippingMode,
            customerServiceEmail
        });

        const cancelledStateView = `
            <div class="tft-delivery-context__status">
                <div class="tft-delivery-context__message">
                    <strong>Echec</strong> de livraison.
                </div>
                <div class="tft-delivery-context__icon">
                    <img src="${TFT.options.host}/public/images/track/canceled.png" alt="canceled"
                         style="width: 45px; height: 45px">
                </div>
            </div>
        `;

        const views = {
            0: waitingOrAssignedStateView,
            5: waitingOrAssignedStateView,
            2: inTransitStateView,
            3: deliveredStateView,
            4: cancelledStateView,
            6: cancelledStateView,
        }

        let deliveryContextAreaView = views[status];
        if(updateSafePlace || updateDropOffPoint){
            deliveryContextAreaView += "<div class='tft-delivery-box-title'>Je souhaite être livré en :</div>"
            deliveryContextAreaView += "<div class='tft-delivery-box-float-buttons'>"
            if(updateSafePlace) deliveryContextAreaView += '<button class="safe-place-delivery-button safe-place-delivery-button--top track-header-button tft-float-button tft-secondary-float-button" data-action="updateSafePlace"> Lieu sûr </button>';
            if(updateDropOffPoint) deliveryContextAreaView += '<button class="dropoff-point-delivery-button dropoff-point-delivery-button--top track-header-button tft-float-button tft-principal-float-button" data-action="updateDropOffPoint"> Relais GLS </button>';
            deliveryContextAreaView += "</div>"
        }

        return deliveryContextAreaView;
    }

    displayInTransitStateView(data) {
        const {clientId, trajectoryType, currentStopPosition = '', trackingZoneNotReached} = data;

        let positionType = '';
        if (trajectoryType === 'next') {
            positionType = `
                <div class="tft-delivery-context__position">
                    position <span>${currentStopPosition}</span>
                </div>
            `
        } else if (trajectoryType === 'past') {
            const icon = clientId === 13 ? '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="50" height="40"><path d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>' :
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="50" height="40"><path d="M400 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm-4 121a31.9 31.9 0 0 0 20 7h64a32 32 0 0 0 0-64h-52.78L356 103a31.94 31.94 0 0 0-40.81.68l-112 96a32 32 0 0 0 3.08 50.92L288 305.12V416a32 32 0 0 0 64 0V288a32 32 0 0 0-14.25-26.62l-41.36-27.57 58.25-49.92zm116 39a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64zM128 256a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64z"/></svg>';

            positionType = `
                <div class="tft-delivery-context__position">
                    ${icon}
                </div>
            `
        }

        if (trackingZoneNotReached) {
            return `
                <div class="tft-delivery-context__status">
                    <div class="tft-delivery-context__message">
                        Recevez des mises à jour de votre livraison en revenant sur cette page.
                    </div>
                </div>`
        }

        return `
            <div class="tft-delivery-context__status">
                <div class="tft-delivery-context__message">
                    Votre colis est en cours de livraison.
                </div>
                ${positionType}
            </div>
        `
    }

    displayDeliveredStateView(data) {
        const {showDeliveryDetails, pictureProof, shippingMode, customerServiceEmail} = data;

        let deliveryDetailsView = '';
        if (showDeliveryDetails && (pictureProof || shippingMode)) {
            deliveryDetailsView = `
                <div class="tft-delivery-proof__container">
                    ${shippingMode ? `<div class="tft-delivery-proof__title">Voici les détails de livraison indiqués par le coursier :</div> <div class="tft-delivery-proof__shipping-mode">${shippingMode}</div>` : ''}
                    ${pictureProof ? `<div class="tft-delivery-proof__title">Preuve de livraison :</div> <div class="tft-delivery-proof__image-container"><img class="tft-delivery-proof__image" src=${pictureProof} alt="delivery proof image"></div>` : ''}
                    ${customerServiceEmail ? `<div class="tft-delivery-proof__email" >Pour toute demande, vous pouvez nous contacter à l'adresse <a href="mailto:${customerServiceEmail}">${customerServiceEmail}</a></div>` : ''}
                </div>
            `;
        }

        const deliveredImageSrc = TFT.options.host + '/public/images/track/delivered.png';
        return `
            <div class="tft-delivery-context__status">
                <div class="tft-delivery-context__message">
                    Votre colis a été <strong>livré</strong>.
                </div>
                <div class="tft-delivery-context__icon">
                    <img src=${deliveredImageSrc} alt="delivered">
                </div>
            </div>
            ${deliveryDetailsView}
        `;
    }

    buildScheduleArea(data) {
        const {
            orderNumber,
            timeSlot,
            dateSlot,
            scheduleAreaActions: {showDeliveryDate, showDeliverySlot, showTrackingNumber, updateDeliverySlot} = {},
        } = data;

        if (!orderNumber && !timeSlot && !dateSlot) return '';

        return `
            <div class="visible-areas-schedule__onclose">
                <div class="tft-draggable-area"></div>
                <div class="tft-schedule-area__header">
                    ${showTrackingNumber ? `<div class="tft-schedule__track-id"><span>${orderNumber}</span></div>` : ''}
                    <div class="tft-draggable-thumb"></div>
                </div>
                ${showDeliverySlot && timeSlot ? `<div class="tft-schedule__time"> ${timeSlot} </div>` : ''}
                ${showDeliveryDate && dateSlot ? `<div class="tft-schedule__date"> ${dateSlot} </div>` : ''}
            </div>
            ${updateDeliverySlot ? `<button id="schedule-order-date-button" class="track-header-button tft-schedule__button" data-action="updateDeliverySlot"> Reprogrammer ma livraison </button>` : ''}
        `;
    }

    buildFloatButtonsArea(data) {
        const {
            consigneeInformationActions: {
                updateAccessInformation,
                updateDeliveryAddress,
                updatePhoneNumber
            } = {},
            scheduleAreaActions: {
                updateDeliverySlot
            } = {},
            deliveryContextActions: {
                updateDropOffPoint,
                updateSafePlace,
            } = {}
        } = data;

        const isEditable = [updateAccessInformation, updateDeliveryAddress, updatePhoneNumber].some(action => action === true);

        return `
            ${isEditable ? '<button id="edit-consignee-information-button" class="track-header-button tft-float-button tft-secondary-float-button" data-action="updateConsigneeInformation">Modifier mes informations</button>' : ''}
            ${updateDeliverySlot ? `<button id="schedule-order-date-button" class="track-header-button tft-float-button tft-principal-float-button" data-action="updateDeliverySlot">Reprogrammer</button>` : ''}
            ${updateSafePlace ? `<button class="safe-place-delivery-button track-header-button tft-float-button tft-secondary-float-button" data-action="updateSafePlace"> Lieu sûr </button>` : ''}
            ${updateDropOffPoint ? `<button class="dropoff-point-delivery-button track-header-button tft-float-button tft-principal-float-button" data-action="updateDropOffPoint"> Relais GLS </button>` : ''}
        `;
    }
}

export default TrackMapDeliveryBox;
